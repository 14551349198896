.filtroContainer {
  color: var(--chakra-colors-filtros-color);
  /* display: flex; */
  /* margin-bottom: 15px;
    margin-right: 30px;
    margin-top: 15px; */
  font-weight: bold;
  font-size: small;
}

/* .pFiltros {
    margin-top: 10px;
    margin-right: 2px;
} */