 .filtersDashboard {
  background-color: var(--chakra-colors-filtros-background);
  border: var(--chakra-colors-filtros-border);
  margin-top: 5px;
}

.searchDashboard {
  color: var(--chakra-colors-searchColor);
  display: flex;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
  font-size: small;
}

.itensDashboard {
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  padding-bottom: 7px;
  padding-left: 10px;
  padding-top: 7px;
  margin-top: 10px;
}

.pSearchDashboard {
  margin-top: 10px;
  margin-right: 2px;
}

.helpIcons {
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
  margin-left: 15px;
  margin-top: 10px;
}

.gradientText {
  background-image: linear-gradient(to bottom, var(--chakra-colors-primaryColor), var(--chakra-colors-secundaryColor) );
  background-clip: text;
  color: black;
  font-size:x-large;
  font-weight: bold;
  /*margin-left: 15px;*/
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.saldoTotal {
  font-size:xx-large;
  margin-bottom: 7px;
}