.cardPagina .conteudoCard,
.cardInstrucoes .conteudoCard{
    line-height: 1.8rem;
}

.cardInstrucoes button{
    margin: 12px 0;
}

.cardInstrucoes ol{
    box-sizing: border-box;
    padding-left: 20px;
}

.cardInstrucoes input{
    margin-top: 12px;
    padding: 8px;
    height: max-content;
}