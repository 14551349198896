.paginaInteiraPDV {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: var(--chakra-colors-pagina-background);
    overflow: hidden;
}

.paginaInteiraPDV .cadastroInteiroPDV {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.paginaInteiraPDV .cadastroInteiroPDV .header{
    position: sticky;
    top: 0;
}