.filtersEstoques {
  background-color: var(--chakra-colors-filtros-background);
  border: var(--chakra-colors-filtros-border);
  margin-top: 5px;
}

.searchEstoques {
  color: var(--chakra-colors-searchColor);
  display: flex;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
  font-size: small;
}

.itensEstoques {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  padding-bottom: 7px;
  padding-left: 10px;
  padding-top: 7px;
  margin-top: 10px;
}

.editarListaEstoque {
  height: 15px;
}
