.inputcheckbox {
  background-color: var(--chakra-colors-elementos-background);
  border: solid 3px rgb(78, 131, 247);
  border-radius: 5px;
  width: 100%;
}

.checkmark::after{
  display: none;
  content: '';
  position: absolute;
}

.container-label .checkmark::after{
  content: '';
 left: 4px;
 top: 0px;
 width: 5px;
 height: 9px;
 transform: rotate(45deg);
 border-right: 3px solid #fff;
 border-bottom: 3px solid #fff;

}

.container-label input:checked ~ .checkmark::after{
  display: block;
}