.loader {
  animation: rotate 1.5s linear infinite;
  border: 10px solid #ccc;
  border-bottom-color: var(--chakra-colors-primaryColor);
  border-radius: 50%;
  height: 50px;
  margin-top: 2rem;
  width: 50px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 200px;
}

.loaderInteiro {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}