.pSearchPedidos {
  margin-top: 10px;
  margin-right: 2px;
}

.bodyPedidos {
  background-color: var(--chakra-colors-elementos-background);
}

.cadastroPedidos {
  color: var(--chakra-colors-elementos-color);
  font-weight: bold;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-top: 15px;
}

.inputsDeCadastroPedidos {
  color: var(--chakra-colors-elementos-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputsDeCadastroPedidosLinha {
  display: flex;
  justify-content: left;
}

.produtosPedidoCadastrados {
  color: var(--chakra-colors-elementos-color);
  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: space-between;
}

.produtosPedido {
  background-color:var(--chakra-colors-checked-background) ;
  color: #04fa7f;
}

.frete {
  background-color: rgb(221, 221, 221);
  color: var(--chakra-colors-elementos-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.entrega{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.entregaOption {
  display: flex;
  margin-left: 10px;
}

.checkOption {
  margin-right: 5px;
}

.botaoIncluirPedido {
  margin-top: 35px;
}

.checkEnderecoCliente {
  display: flex;
  flex-direction:row;
}

.filtersPedidos {
  background-color: var(--chakra-colors-filtros-background);
  border: var(--chakra-colors-filtros-border);
  margin-top: 5px;
}

.itensPedidos {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
}

.classePedidos {
  color: var(--chakra-colors-elementos-color);
  font-weight: bold;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-top: 10px;
}

.searchPedidos {
  color: var(--chakra-colors-searchColor);
  display: flex;
  margin-bottom: 15px;
  margin-right: 30px;
  margin-top: 15px;
  font-weight: bold;
  font-size: small;
}

.botaoIncluirPedido {
  margin-top: 27px;
}

.totalPedido {
  color:var(--chakra-colors-elementos-color);
  margin-left: 85%;
  
}

.valorTotal {
  color:rgb(19, 179, 253);
  margin-left: 85%;
}
