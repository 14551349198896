.classeProdutos {
  background-color: var(--chakra-colors-elementos-background);
  border-left: solid 5px #16d105;
  border-radius: 7px;
  display: flexbox;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

.classeProdutosNum{
  background-color: var(--chakra-colors-elementos-background);
  display: flexbox;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

.bodyProdutos {
  margin-left: 15px;
  background-color: var(--chakra-colors-elementos-background);
}

.barraSupProdutos {
  display: flex;
  justify-content: space-between;
}

.iconeExpansao {
  height: 2%;
  width: 2%;
}

.cadastroProdutos {
  color: var(--chakra-colors-elementos-color);
  font-weight: bold;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-top: 15px;
}

.inputsDeCadastroProdutos {
  color: var(--chakra-colors-elementos-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputCadastroProdutos {
  margin-bottom: 20px;
  margin-right: 50px;
  margin-top: 10px;
}

.inputsDeCadastroProdutosLinha {
  display: flex;
  justify-content: left;
}

.frete {
  background-color: rgb(221, 221, 221);
  color: var(--chakra-colors-elementos-color);
  display: flex;
  justify-content: space-between;
}

.entrega {
  display: flex;
  justify-content: space-around;
  margin-bottom: 7px;
  margin-left: 7px;
  margin-top: 7px;
}

.entregaOption {
  display: flex;
  margin-left: 10px;
}

.checkOption {
  margin-right: 5px;
}

.totalProdutos {
  display: flex;
  flex-direction: column;
  margin-left: 90%;
}

.botaoIncluirProduto {
  margin-top: 35px;
}

.checkEnderecoCliente {
  display: flex;
  flex-direction:row;
}

.filtersProdutos {
  background-color: var(--chakra-colors-filtros-background);
  border: var(--chakra-colors-filtros-border);
  margin-top: 5px;
}

.itensProduto {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
}

.pProdutos {
  margin-right: 150px;
}

.pSearchProdutos {
  margin-top: 10px;
  margin-right: 2px;
}

.searchProdutos {
  color: var(--chakra-colors-searchColor);
  display: flex;
  margin-bottom: 15px;
  margin-right: 30px;
  margin-top: 15px;
  font-weight: bold;
  font-size: small;
}

.optionsFilterProdutos {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

.botaoPlus {
  display: flex;
  height: 100%;
  margin-left: 10px;
  width: 100%;
}

.depósitos:hover {
background-color: #b9f8fa;
color: #02a0a5;
}