body, #root {
  max-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--chakra-colors-pagina-background);
}

.paginaPrincipal {
  max-height: 100vh;
  width: 100%;
  background-color: var(--chakra-colors-pagina-background);
}

.cadastroInteiro {
  min-height: 100%;
  height: fit-content;
  flex: 1;
  overflow: auto;
}

.searchbuttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;  
}

.notChecked {
  background-color: var(--chakra-colors-elementos-background);
  color: black;
}

.checked {
  background-color: var(--chakra-colors-checked-background);
  color: var(--chakra-colors-checked-color);
}

.card {
  background-color: var(--chakra-colors-elementos-background);
  border-radius: 7px;
  width: 100%;
}

.cadastrados {
  background-color: var(--chakra-colors-elementos-background);
  color: var(--chakra-colors-elementos-color);
  max-width: 100%;
}

.filtersBox {
  background-color: var(--chakra-colors-filtros-background);
  border: var(--chakra-colors-filtros-border);
  border-radius: 7px;
  margin-top: 5px;
  padding-bottom: 10px;
  padding-left: 3px;
}

.itens {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  padding-left: 10px;
  padding-top: 7px;
  margin-top: 10px;
}

/* Relatórios */

.inputCadastroCodigo {
  padding-left: 20px;
}

.inputtitle {
  margin-bottom: 5px;
}

.opcoesCadastro {
  display: flex;
}

.opcoestitle {
  margin-left: 5px;
  margin-right: 10px;
}

.filters{
  background-color: var(--chakra-colors-filtros-background);
  border: var(--chakra-colors-filtros-border);
}

.optionsFilter {
  display: flex;
  flex-direction: row;
}

.search {
  color: var(--chakra-colors-searchColor);
  display: flex;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
  font-size: small;
}

.itensRelatorio {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  padding-bottom: 7px;
  padding-left: 10px;
  padding-top: 7px;
  margin-top: 10px;
}