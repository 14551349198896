.bodyNFCe {
  background-color: var(--chakra-colors-elementos-background);
  margin-left: 10px;
}

.classecadastro {
  background-color: var(--chakra-colors-elementos-background);
  border-left: solid 5px #16d105;
  border-radius: 7px;
  margin-top: 20px;
}

.cadastroNFCe {
  color: var(--chakra-colors-elementos-color);
  font-weight: bold;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-top: 15px;
}

.inputsDeNFCe {
  color: var(--chakra-colors-elementos-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputsDeNFCe {
  color: var(--chakra-colors-elementos-color);
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 30px;
}

.filtersNFCe {
  background-color: var(--chakra-colors-filtros-background);
  border: var(--chakra-colors-filtros-border);
}

.optionsFilterNFCe {
  display: flex;
  flex-direction: row;
}

.searchNFCe {
  color: var(--chakra-colors-searchColor);
  display: flex;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
  font-size: small;
}

.itensNFCe {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  display: flex;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-top: 7px;
  margin-top: 10px;
}

.pSearchNFCe {
  margin-top: 10px;
  margin-right: 2px;
}

.notasNFCe {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
}