.botaoPlusNC {
  margin-left: 5px;
  margin-top: 22px;
  width: 100%;
}

.botaoMinusNC {
  margin-left: 10px;
  margin-right: 15px;
  margin-top: 22px;
  width: 100%;
}

.buttonsIncDecNC {
  display: flex;
}

#incrementAmountNC {
  border: var(--chakra-colors-input-border);
  border-radius: 5px;
  font-size:medium;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 100%;
}

.buttonAddNC {
  background-color: rgb(250, 192, 159);
  border: solid 1px rgb(252, 132, 62);
  border-radius: 7px;
  color: rgb(247, 122, 51);
  font-size: 16px;
  font-weight: bolder;
  height: 40px;
  margin-left: 10px;
  margin-top: 25px;
}
  