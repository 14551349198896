.PaginaPDV .textoVerde {
  color: var(--chakra-colors-successColor);
}

.FormikFormPaginaPDV {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.PaginaPDV {
  overflow: hidden;
}

.PaginaPDV>div {
  flex-shrink: 0;
}

.PaginaPDV>div.mainPDV {
  flex-shrink: 1;
}

td>.buttonnot{
  padding: 0 1rem;
}

@media (max-width: 600px) {
  .PaginaPDV>div.mainPDV {
    flex-wrap: wrap;
    height: fit-content;
    flex-shrink: 0;
  }

  .PaginaPDV{
    overflow-y: scroll;
    height: fit-content;
  }
}