
.itens {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
}

.pSearchClientes {
  margin-top: 10px;
  margin-right: 2px;
}
