.iconeSetaVerde {
  color: #2dce89;
}

.iconeSetaVermelha {
  color: red;
}

.iconeSetaBranca {
  color: #fff ;
}