.bodyMovimentacoesEstoque {
  background-color: var(--chakra-colors-elementos-background);
  margin-left: 10px;
}

.notasMovimentacoesEstoque {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
}
.botaoPlusMF {
  margin-left: 10px;
  margin-top: 22px;
  
}

.botaoMinusMF {
  margin-left: 10px;
  margin-top: 22px;
}

.buttonsIncDec {
  display: flex;
}

#incrementAmount {
  border: var(--chakra-colors-input-border);
  border-radius: 5px;
  font-size:medium;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 100%;
}

.buttonAdd {
  background-color: rgb(250, 192, 159);
  border: solid 1px rgb(252, 132, 62);
  border-radius: 7px;
  color: rgb(247, 122, 51);
  font-size: 16px;
  font-weight: bolder;
  height: 40px;
  margin-left: 10px;
  margin-top: 25px;
}
  