 .bodyCheques {
  background-color: var(--chakra-colors-elementos-background);
  margin-left: 10px;
}

.classecadastro {
  background-color: var(--chakra-colors-elementos-background);
  border-left: solid 5px #16d105;
  border-radius: 7px;
  margin-top: 20px;
}

.cadastroCheques {
  color: var(--chakra-colors-elementos-color);
  font-weight: bold;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-top: 15px;
}

.inputsDeCheques {
  color: var(--chakra-colors-elementos-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputsDeCheques {
  color: var(--chakra-colors-elementos-color);
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 30px;
}

.filtersCheques{
  background-color: var(--chakra-colors-filtros-background);
  border: var(--chakra-colors-filtros-border);
}

.optionsFilterCheques {
  display: flex;
  flex-direction: row;
}

.searchCheques {
  color: var(--chakra-colors-searchColor);
  display: flex;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
  font-size: small;
}

.itensCheques {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
}

.itensChequesP1 {
  margin-right: 200px ;
}

.pSearchCheques {
  margin-top: 10px;
  margin-right: 2px;
}
