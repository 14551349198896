.MenuLateralContainer {
  z-index: 999999;
}

.navegacaomenulateral {
  display: flex;
  flex-direction: column;
  width: 250px;
  position: sticky;
  top: 0px;
  z-index: 999999;
}

.headermenulateral {
  background-color: var(--chakra-colors-primaryColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 40px 15px 20px;
  z-index: 999999;
}

.headerlogo {
  margin-bottom: 15px;
  max-height: 35px;
  min-width: 164px;
  z-index: 999999;
}

.iconemenu {
  color: var(--chakra-colors-elementos-background);
  margin-bottom: 17px;
  height: 40px;
  width: 40px;
  z-index: 999999;
}

@media (max-width: 600px) {
  .navegacaomenulateral {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    background-color: var(--chakra-colors-elementos-background);
    z-index: 999999;
  }

  .menulateral {
    width: 100%;
    z-index: 999999;
  }

  .hidemenu {
    display: none;
    visibility: none;
    z-index: 999999;
  }

  .headermenulateral {
    padding-left: 15%;
    padding-right: 15%;
  }

  .headerlogo {
    max-width: 164px;
    width: calc(100% - 40px);
    min-width: 0;
    flex-shrink: 1;
  }
}