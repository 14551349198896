 .bodyNFe {
  background-color: var(--chakra-colors-elementos-background);
  margin-left: 10px;
}

.classecadastro {
  background-color: var(--chakra-colors-elementos-background);
  border-left: solid 5px #16d105;
  border-radius: 7px;
  margin-top: 20px;
}

.cadastroNFe {
  color: var(--chakra-colors-elementos-color);
  font-weight: bold;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-top: 15px;
}

.inputsDeNFe {
  color: var(--chakra-colors-elementos-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputsDeNFe {
  color: var(--chakra-colors-elementos-color);
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 30px;
}

.filtersNFe{
  background-color: var(--chakra-colors-filtros-background);
  border: var(--chakra-colors-filtros-border);
}

.optionsFilterNFe {
  display: flex;
  flex-direction: row;
}

.searchNFe {
  color: var(--chakra-colors-searchColor);
  display: flex;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
  font-size: small;
}

.itensNFe {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
}

.pSearchNFe {
  margin-top: 10px;
  margin-right: 2px;
}

.notasNFE {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
}