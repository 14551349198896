.paginaInteiraGradeProdutos{
  width: 100%;  
  height: 100%;
  display: flex;
  flex-direction: row;
}

.cadastroInteiroGradeProdutos {
  height: 100%;
  flex: 1;
}

.pSearchGradeProdutos {
  margin-top: 10px;
  margin-right: 2px;
}

.iconeLixeira {
  height: 25px;
}
