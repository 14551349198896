.searchLancamentosFinanceiros {
  color: var(--chakra-colors-searchColor);
  display: flex;
  margin-left: 5px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
  font-size: small;
}

.itensLancamentosFinanceiros {
  background-color: var(--chakra-colors-itens-background);
  border: var(--chakra-colors-itens-border);
  border-radius: 7px;
  color: var(--chakra-colors-itens-color);
  display: flex;
  margin-left: 4px;
  margin-top: 10px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-top: 7px;
  width: 100%;
}

.itensLancamentosFinanceirosP1 {
  margin-right: 200px;
}

.arquivosCadastrados {
  color: var(--chakra-colors-elementos-color);
  font-weight: bold;
}

.arquivo {
  background-color:var(--chakra-colors-checked-background) ;
  color: #04fa7f;
}

.buttonAddNome {
  background-color: rgb(164, 168, 168);
  border-radius: 50%;
  color: var(--chakra-colors-elementos-background);
  font-size: larger;
  font-weight: bolder;
}

.buttonAddArquivo {
  background-color: rgb(6, 250, 250);
  border-radius: 50%;
  color: var(--chakra-colors-elementos-background);
  font-size: larger;
  font-weight: bolder;
}

.pSearchLancamentos {
  margin-top: 10px;
  margin-right: 2px;
}
