.dashboardCardContainer {
    display: flex;
    flex-direction: column;
    background-color: var(--chakra-colors-elementos-background);
    border-radius: 7px;
    max-height: 100%;
}

.dashboardCardContainerCabecario {
    background-color: var(--chakra-colors-elementos-background);
    border-left: solid 5px var(--chakra-colors-primaryColor);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.dashboardCardContainer .titulo {
    color: var(--chakra-colors-elementos-color);
    font-weight: bold;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-top: 15px;
}

.dashboardCardContainer .paragrafo {
    color: var(--chakra-colors-elementos-color);
    font-size: smaller;
    padding-bottom: 5px;
    padding-left: 10px;
}

.dashboardCardContainer .conteudo{
    padding: 6px 16px 16px;
}