.bodyEmpresas {
  margin-left: 10px;
  background-color: var(--chakra-colors-elementos-background);
}

.editarListaEmpresas  {
  height: 15px;
}

.excluirListaEmpresas  {
  height: 20px;
}