.inputautocomplete {
  border: var(--chakra-colors-input-border);
  border-radius: 5px;
  font-size:small;
  padding-bottom: 7px;
  padding-top: 7px;
  width: 100%;
}

.ui-autocomplete-loading { background:url('https://www.aspsnippets.com/demos/img/loader2.gif') no-repeat right center }

.ui-helper-hidden-accessible { display:none; }