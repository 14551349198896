.menulateral {
  background-color: var(--chakra-colors-elementos-background);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;
  position: relative;
  left: 0;
  width: 250px;

  max-height: 100%;

  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.menulateral::-webkit-scrollbar {
  display: none;
}

.linksmenu {
  color: var(--chakra-colors-menu-link);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-weight: 500px;
  padding: 20px 24px 8px;
}

.linksmenubaixo {
  align-content: space-around;
  color: var(--chakra-colors-menu-link);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-weight: 500px;
  padding: 16px 10px;
}

.menulinks {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.icone {
  height: 15px;
  margin-right: 10px;
  margin-top: 3px;
  width: 15px;
  color: inherit;
}

.logout {
  height: 17px;
  margin-right: 5px;
  margin-top: 3px;
  width: 17px;
}

.linkmenu {
  color: var(--chakra-colors-menu-link);
  display: flex;
  flex-wrap: wrap;
  padding: 7px;
}

.linkmenu * {
  color: var(--chakra-colors-menu-link);
  stroke: var(--chakra-colors-menu-link);
}

.linkinterno {
  display: flex;
  border-left: solid 3px var(--chakra-colors-menu-link);
  color: var(--chakra-colors-menu-link);
  justify-content: left;
  padding: 7px;
}

.linkmenu:active,
.linkmenu:hover,
.linkinterno:active,
.linkinterno:hover {
  background-color: rgb(216, 243, 253);
  background-color: var(--chakra-colors-primaryColor15);
  color: var(--chakra-colors-primaryColor);
  stroke: var(--chakra-colors-primaryColor);
  border-left-color: var(--chakra-colors-primaryColorDark);
}

.linkmenu:hover *,
.linkmenu:active *,
.linkinterno:hover *,
.linkinterno:active * {
  color: var(--chakra-colors-primaryColor);
  stroke: var(--chakra-colors-primaryColor);
}

.linksmenuinterno {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 10px;
}

.linksmenuinternorel {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 20px;
}

@media (max-width: 600px) {
  .linksmenu,
  .linksmenubaixo {
    padding-left: 20%;
    padding-right: 20%;
  }
}