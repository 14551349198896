.inputtoggle {
  outline: none;
    position: relative;
    z-index: 1;
    margin: 2px;
    padding: 0;
    cursor: pointer;
    width: 48px;
    height: 24px;
    overflow: hidden;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.inputtoggle::before, .inputtoggle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: left .15s cubic-bezier(.25, .8, .25, .1),
              transform .15s ease-in;
}

.inputtoggle::before {
  background-color: #ccc;
  width: 100%;
  height: 100%;
  border-radius: 28px;
}

.inputtoggle::after {
  margin: 2px 0 0 2px;
  background: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.inputtoggle:checked::before {
  background-color: #0d90e7;
}

.inputtoggle:checked::after {
  left: 24px;
}

.inputtoggle:not([disabled]):active::after {
  transform: scale(1.15, 0.85);
}

.inputtoggle:disabled::before {
   background-color: #b1b4b7 !important;
}

.inputtoggle:disabled::after {
   background-color: #dcd8d8 !important;
}