.paginaLogin, .loginScreen {
  width: 100%;
  height: 100vh;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
}

.loginScreen { 
  background:#fbfbfb;
}

.bgLateral{
  z-index: 1;
	position:absolute;
	width:50%;
	height:100%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.tituloLogin{
	padding-top:100px;
	padding-right:250px;
	padding-left:30px;
	color:#fff;
	font-size:25px;
}

.hide {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.logoERP {
  margin-bottom: 1.5rem;
}

.formSignin {
  z-index: 2;
  width: 100%;
  max-width: 330px;
  margin: auto;

  background:#fff;
	-webkit-box-shadow: 0px 0px 15px 0px rgba(150, 150, 150, 0.8);
	-moz-box-shadow:    0px 0px 15px 0px rgba(150, 150, 150, 0.8);
	box-shadow:         0px 0px 15px 0px rgba(150, 150, 150, 0.8);
	padding:32px;
	width:80%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.formSignin .checkbox {
  padding: 8px 0;
  font-weight: 400;
}


.formSignin:focus, input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.formSignin input[type="email"], 
.formSignin input[type="password"] {
	width: 100%;
	margin-top:15px;
  padding-top:8px;
  padding-bottom:8px;
  padding-left:16px;
  padding-right:6px;
  border: 2px solid #d1d1d1;
  border-radius: 10px;
}

.btGeralEntrar{
  width:100%;
  background:    #01c6e8;
  background:    linear-gradient(90deg, #01c6e8, #06dd9f);
  border:        2px solid #01c6e8;
  border-radius: 10px;
  padding:       12px 25px;
  color:         #ffffff;
  display:       inline-block;
  font:          normal 13px/1 "Montserrat-Medium", sans-serif;
  text-align:    center;
  margin-top: 1.5rem;
}

.btGeralEntrar:hover{
  background: linear-gradient(90deg, rgba(1,198,232,0) 0%, rgba(6,221,159,0) 100%);
  color:         #01c6e8;
}

.separadorOu{
	color:#01c6e8;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.btCriar{
  width:60%;
  background:    #5ac000;
  border:        2px solid #5ac000;
  border-radius: 10px;
  padding:       12px 25px;
  color:         #ffffff;
  display:       inline-block;
  font:          normal 13px/1 "Montserrat-Medium", sans-serif;
  text-align:    center;
}

.btCriar:hover{
  background: linear-gradient(90deg, rgba(1,198,232,0) 0%, rgba(6,221,159,0) 100%);
  border:        2px solid #01c6e8;
  color:         #01c6e8;
}

@media (max-width: 950px) {
  .bgLateral{
    width: 100%;
  }

	.tituloLogin{
		display: none;
	}
}
