.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 16px;
  background-color: var(--chakra-colors-pagina-background);
  z-index: 999;

  overflow: hidden;
  height: 64px;
  color: var(--chakra-colors-elementos-color);
}

.saudacao {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
}

.bomdia {
  color: var(--chakra-colors-secundaryColor);
  font-weight: bold;
  word-wrap: unset;
  margin-right: 5px;
}

.user {
  color: var(--chakra-colors-primaryColor);
  font-weight: bold;
}

.iconemenuHeader {
  display: none;
}

.icones {
  display: flex;
  /* margin-right: 15px; */
  display: flex;
  align-items: center;
}

.notificacao {
  position: relative;
}

.numeroNotificacao {
  font-size: 12px;
  position: absolute;
  right: -2px;
  top: -6px;
}

.notificacaoIcone {
  margin-top: 6px;
}

.separator {
  opacity: 0.5;
  border-left: 1px solid var(--chakra-colors-elementos-color);
  ;
  width: 0px;
  height: 80%;
  margin: 0 16px;
}

.cargo {
  font-size: 14px;
}

.funcionarioHeader {
  display: flex;
  align-items: center;
}

.funcionarioHeader .img {
  min-height: 36px;
  min-width: 36px;
  border-radius: 50%;
  margin-right: 4px;
}

.funcionarioHeader img.img {
  height: 36px;
  width: 36px;
  object-fit: cover;
}

.funcionarioHeader div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.funcionarioHeader p {
  font-weight: bold;
  font-size: 1em;
}

.funcionarioHeader span {
  font-size: 0.875em;
  line-height: 0.9em;
}

@media (max-width: 600px) {
  .header {
    padding: 8px 16px;
  }

  .saudacao {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .iconemenuHeader {
    display: inherit;
    height: 25px;
    width: 23px;
    margin-right: 8px;
  }

  .bomdia,
  .user {
    display: none;
    visibility: none;
  }
}