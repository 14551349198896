.paginaInteira {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: var(--chakra-colors-pagina-background);
    overflow: hidden;
}

.paginaInteira>.MenuLateralContainer {
    position: sticky;
    top: 0px;
}

.paginaInteira .cadastroInteiro {
    width: auto;
    height:100%;
    overflow: auto;
    padding-bottom: 32px;
}

.paginaInteira .cadastroInteiro .contedoCadastro {
    width: 100%;
    min-height: calc(100% - 64px);
    max-width: 1080px;
    margin: auto;
}

.paginaInteira .cadastroInteiro .header {
    width: 100%;
    max-width: 1080px;
    margin: auto;
    
    position: sticky;
    position: -webkit-sticky;
    top:0;
}