.searchbuttonsbar,
.searchbuttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}


.buttonsBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
}

.searchBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  min-width: 220px;
}

.breadcrumbBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: fit-content;
  min-width: 220px;
}

.fontBreadcrumb {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  cursor: pointer;
}

.searchbuttonsbar .iconeFiltro,
.searchbuttons .iconeFiltro {
  color: inherit;
}