.pSearchCondicoesPagamento {
  margin-top: 10px;
  margin-right: 2px;
}

.editarListaCondicoesPagamento {
  height: 15px;
}

.excluirListaCondicoesPagamento  {
  height: 20px;
}