.inputSearchContainer {
  background-color: rgb(221, 221, 221);
  border: var(--chakra-colors-input-border);
  border-radius: 5px;
  font-size: medium;
  padding: 6px;
  width: 100%;
  max-width: 400px;
  min-width: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.iconSearch {
  font-size: 22px;
}

.inputSearch {
  padding-left: 8px;
  background-color: rgb(221, 221, 221);
  outline: none;
  flex: 1;
}

/* ÓDIGO PARA MUDAR O X DO SEARCH */
/* PRECISA TROCAR LINK POR IMAGEM DE ICONE*/
/* input[type="search"] {
  color: inherit;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
} */