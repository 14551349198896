.headerPDV {
  display: flex;
  justify-content:space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 16px;
  background-color: var(--chakra-colors-pagina-background);
  z-index: 999;

  overflow: hidden;
  background-color: var(--chakra-colors-primaryColor);
  flex-shrink: 0;
}

.headerPDV .headerlogo {
  margin: 0;
  max-height: 35px;
  min-width: 164px;
  z-index: 999999;
}

.headerPDV .logoContainer {
  flex:1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerPDV h2{
  flex: 3;
  margin: 8px;
  font-size: 1.75rem;
  color: var(--chakra-colors-white);
  text-align: center;
  text-transform: capitalize;
  font-variant: small-caps;  
}

.headerPDV button{
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 8px;
}

@media (max-width: 500px) { 
  .headerPDV .logoContainer {
    flex: 3;
    justify-content: center;
    margin: 8px;
  }
  .headerPDV button{
    flex: 2;
    justify-content: center;
  }

  .headerPDV h2{
    min-width: 100%;
    order: 3;
  }
}